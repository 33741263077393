












































































































































































import {
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useCalendar, useBrand, useArea, useGeralImport } from "@/services";
import { convertToUSDate } from "@/services/utils";

// Interfaces
import { IOption, IUserData } from "@core/services/interfaces/IUtil";
import { IResponseGetBlockedDates } from "@core/services/interfaces/geralEmplacamento/calendar/ICalendarService";
import { IResponseListBrands } from "@core/services/interfaces/vehicle/brands/IBrandService";
import { IResponseListInfluenceScopes } from "@core/services/interfaces/covarege-area/area/IAreaService";

@Component({
  name: "ValidateFilters",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    AvButton,
    AvSkeletonInput,
    BFormInvalidFeedback,
  },
})
export default class ValidateBrandFilters extends Vue {
  @Prop({ required: true, default: [] }) validationTypeOptions!: IOption[];

  areaOptions: IOption[] = [];
  brandOptions: IOption[] = [];

  startDate = "";
  endDate = "";
  validationType: number | null = null;
  areas: string[] = [];
  brands: string[] = [];

  saving = false;
  loadingBrands = false;
  loadingDate = false;
  loadingAreas = false;

  dateConfig = {
    mode: "single",
    dateFormat: "d/m/Y",
    disable: [""],
    maxDate: new Date(),
    locale: this.$t("flatPickr.locale"),
  };
  endDateConfig: {
    disable: string[];
    minDate: string | Date | null;
    maxDate: string | Date | null;
  } = {
    ...this.dateConfig,
    disable: [""],
    minDate: null,
    maxDate: new Date(),
  };

  formValidation = formValidation();

  // Computeds
  get loading(): boolean {
    return this.loadingBrands || this.loadingDate || this.loadingAreas;
  }

  get currentUser(): IUserData {
    return this.$store.getters["appConfig/currentUser"];
  }

  // LifeCycle
  created() {
    this.fetchListBrands();
    this.fetchGetBlockedDates();
    this.fetchListInfluence();
  }

  @Watch("startDate")
  setMinDate() {
    if (this.startDate != "") {
      let minDate = new Date(convertToUSDate(this.startDate));
      minDate.setDate(minDate.getDate() + 1);
      this.endDateConfig.minDate = minDate;
    }
  }

  // Methods
  reduceValue(val: { label: string; value: any }) {
    return val.value;
  }

  fetchGetBlockedDates() {
    this.loadingDate = true;
    const startDate = "2000-01-01";

    useCalendar
      .requestGetBlockedDates(startDate)
      .then((response: IResponseGetBlockedDates) => {
        const disabledDates = response.data.Data.map(
          (date: string) => date.split("T")[0]
        );

        const disableArray = disabledDates.concat([
          function (date: Date) {
            const dateString = date.toISOString().split("T")[0];
            return (
              disabledDates.includes(dateString) ||
              date.getDay() === 0 ||
              date.getDay() === 6
            );
          },
        ]);

        this.dateConfig.disable = disableArray;
        this.endDateConfig.disable = disableArray;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao consultar lista de datas bloqueadas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingDate = false;
      });
  }

  fetchListInfluence() {
    this.loadingAreas = true;

    useArea
      .requestListInfluenceScopes()
      .then((response: IResponseListInfluenceScopes) => {
        this.areaOptions = response.data.Data.map((area) => ({
          label: area.Nome,
          value: area.Codigo,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao consultar lista de áreas de influência!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingAreas = false;
      });
  }

  fetchListBrands() {
    this.loadingBrands = true;

    useBrand
      .requestList({
        draw: 1,
        order: [
          {
            column: 0,
            dir: "asc",
          },
        ],
        columns: [
          {
            data: "Nome",
          },
        ],
      })
      .then((response: IResponseListBrands) => {
        this.brandOptions = response.data.data.map((brand) => ({
          label: brand.Nome,
          value: brand.Codigo,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao consultar lista de montadoras!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingBrands = false;
      });
  }

  requestBrandValidation() {
    this.$refs.refFormObserver
      // @ts-ignore
      .validate()
      .then((success: boolean) => {
        if (success) {
          this.saving = true;
          useGeralImport
            .requestValidateBrand({
              dataInicio: convertToUSDate(this.startDate),
              dataFim: convertToUSDate(this.endDate),
              disponibilizarArquivoFtp: false,
              areasInfluenciaCodigos:
                this.areas.length > 0 ? this.areas : undefined,
              fabricantesCodigos:
                this.brands.length > 0 ? this.brands : undefined,
              tipo: this.validationType || 3,
              usuarioId: this.currentUser.id,
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Validação requisitada com sucesso!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetchData");
            })
            .catch((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error ao requisitar validação!",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              return response;
            })
            .finally(() => {
              this.saving = false;
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Preencha os campos obrigatórios!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      });
  }
}
